<template>
  <div class="circleSearch_main">
    <pageHead class="messageMainView_head" title="圈子" />
    <div class="search_box">
      <circleSearchs
        placeholder="搜索圈子内帖子"
        @click="handleClick"
        @input="handleInput"
        :disabled="false"
      ></circleSearchs>
      <span class="cancel_title" @click.prevent="handleCancel()">取消</span>
    </div>
    <div v-infinite-scroll="loadMore" class="cont_list">
      <qnlist :topicData="topicData" :isload="isload"></qnlist>
    </div>
  </div>
</template>

<script>
import circleSearchs from '@/components/searchBox'
import pageHead from '@/components/pageHead'
import qnlist from '@/views/app/circle/components/circleXq/common/cardItem.vue'
import { getKeywordSearchList } from '@/api/circle'
import {handleHeightLight,handleContentHeightLight} from "@/utils/utils";

export default {
  name: 'circleSearch', // 圈子搜索
  components: {
    circleSearchs,
    pageHead,
    qnlist
  },
  data() {
    return {
      searchParams: {
        keyword: '',
        pageNum: 1,
        pageSize: 10
      },
      topicData: [],

      isMoreFinished: false, // 是否加载完毕
      isload: false
    }
  },
  created() {
    this.searchParams.circleId = this.$route.query.circleid
  },
  mounted() {
    setTimeout(()=>{
      window.scrollTo(0, 0);
    },400)
  },
  methods: {
    /**
     * 加载更多数据
     */
    loadMore() {
      if (this.searchParams.keyword && !this.isMoreFinished) {
        this.searchParams.pageNum++
        this.handleSearch()
      }
    },
    /**
     * todo 搜索接口
     */
    async handleSearch(type) {
      const res = (await getKeywordSearchList(this.searchParams)) || {}
      console.log(res)
      const { records } = res || []
      if (type === 'reset') {
        this.topicData = records
      } else {
        this.topicData = this.topicData.concat(records)
      }
      if (records.length < 10) {
        this.isMoreFinished = true
      }
      this.topicData.forEach(item=>{
         item.title = handleHeightLight(item.title,this.searchParams.keyword)
                item.content = handleContentHeightLight(item.content,this.searchParams.keyword)
      })
      this.isload = true
    },
    /**
     * todo 输入内容立即搜索
     */
    handleInput(value) {
      this.isMoreFinished = false
      value = value.trim()
      if (value) {
        this.searchParams.keyword = value
        this.searchParams.pageNum = 1
        this.handleSearch('reset')
      } else {
        this.topicData = []
      }
    },
    /**
     * enter 搜索
     */
    handleClick(value) {
      this.handleInput(value)
    },
    /**
     * 取消搜索
     */
    handleCancel() {
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
.circleSearch_main {
  background: #fff;

  .search_box {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 12px;

    ::v-deep .van-search {
      width: 302px;
      padding-left: 15px;
      padding-right: 0;
    }
  }

  ::v-deep {
    .van-search__content {
      border-radius: 70px;
    }
  }

  .cancel_title {
    padding: 4px 15px;
    font-size: 14px;
    text-align: center;
    color: #000000;
  }

  .cont_list {
    padding: 0 15px;
    height: calc(100vh - 110px);
    overflow: scroll;
  }
}
</style>
